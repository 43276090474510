import Body from '../components/Body';
import Bio from '../components/Bio';
import Writing from '../components/Writing';
import Work from '../components/Work';
import Contact from '../components/Contact';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function HomePage() {
  return (
    <Body>
      <Row className="pt-5">
        <Col className="constrained pt-5" xs={12}>
          <h1 className="mb-4">Alex Ray</h1>
          <Bio/>
          <Writing />
          <Work />
          <Contact />
        </Col>  
      </Row>
    </Body>
  );
}