import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Contact() {
  return (
    <Row className="mb-4">
      <Col>
        <h4>Want to connect? Get in touch:</h4>
        <p>Twitter: <a href="https://twitter.com/___xray" target="_blank" rel="noreferrer">@___xray</a></p>
        <p>LinkedIn: <a href="https://linkedin.com/in/jalexray" target="_blank" rel="noreferrer">Alex Ray</a></p>
        <p>GitHub: <a href="https://github.com/jalexray" target="_blank" rel="noreferrer">@jalexray</a></p>
      </Col>
    </Row>
  )
}
