import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Bio() {
  return (
    <Row className="mb-4">
      <Col>
        <h4>About Me</h4>
        <p>I'm a Principal at <a href="https://www.psl.com">Pioneer Square Labs</a> (A.K.A. PSL), where I build & invest in startups.</p>
        <p>Prior to joining PSL, I worked at McKinsey & Co., where I 1) helped companies launch startups & 2) conducted due diligence for PE firms. I also founded Parade (a generative design startup backed by Y Combinator) and worked with early stage startups through Oxbow Ventures, a venture capital firm in Jackson, Mississippi. I have an MBA from Harvard Business School and an undergraduate degree from Ole Miss.</p>
        <p>I'm a full stack developer, most proficient in Python. I am interested in machine learning/artificial intelligence, automation, augmented reality, software for industrial applications, and alternative food. Generally, I like to think about the future, and if you're working on something that might shape it, I'd love to talk.</p>
        <p>I spend my free time cooking, gardening, fly fishing, and exploring the Pacific Northwest.</p>
      </Col>
    </Row>
  )
}
