import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BsArrowRightShort } from 'react-icons/bs';

export default function Experience({iconFile, org, headline, explanation, timeline, linkURL=false, linkText}) {
  return (
    <Row className="mb-3">
      <Col className="icon_and_text align-middle">
        <img src={iconFile} alt={"icon for " + org}/>
        <h4 className="mb-2">{org}: {headline}</h4>
        <p className="mb-2">{explanation}</p>
        <p className="subtitle">{timeline}.{ linkURL ? <a href={linkURL} target="_blank" rel="noreferrer">{linkText}<BsArrowRightShort /></a> : null }</p>
      </Col>
    </Row>
  )
}
