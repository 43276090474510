import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Experience from './Experience.js';

export default function Contact() {
  return (
    <Row className="mb-4">
      <Col>
        <h4 className="mb-3">My Work</h4>
        <Experience 
          iconFile="uifiller.svg"
          org="UI Filler"
          headline="Better Placeholders for UI Designs"
          explanation="UI Filler, built with React, offers better text and image placeholders for designers in a simple interface."
          timeline="2022"
          linkURL="https://sweetloremipsum.vercel.app/"
          linkText="View Live"
        />
        <Experience 
          iconFile="parade.svg"
          org="Parade"
          headline="Algorithmic Design for Marketing Collateral"
          explanation="Parade uses machine learning to help entrepreneurs create brands that they are proud of."
          timeline="2020-2022"
          linkURL="https://www.youtube.com/watch?v=kO5a715UXFU"
          linkText="View Demo"
        />
        <Experience 
          iconFile="rzero.svg"
          org="R Zero"
          headline="Predictive Analytics for Healthcare"
          explanation="R Zero uses machine learning to identify when a patient might need preventive care."
          timeline="2016-2018"
          linkURL="http://rzerotracing.com/"
          linkText="View Live"
        />
        <Experience 
          iconFile="jar.svg"
          org="Jar DevStudio"
          headline="Augmented Reality + Arcade Games on iOS"
          explanation="Jar Development Studio built and released several apps on the iOS ecosystem, using Swift (UIKit, ARKit, and GameKit)."
          timeline="2016-2019"
          linkURL="https://github.com/jalexray/AR-Example"
          linkText="View Code Sample"
        />
        <Experience 
          iconFile="zyn.svg"
          org="Zyn Careers"
          headline="Job Matchmaking for Millennials"
          explanation="Zyn Careers used machine learning to help millennials find jobs they would love."
          timeline="2014-2016"
        />
        
      </Col>
    </Row>
  )
}
