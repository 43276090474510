import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Writing() {
  return (
    null
    // <Row className="mb-4">
    //   <Col>
    //   </Col>
    // </Row>
  )
}
