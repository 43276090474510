import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';

export default function Body({ children }) {
  return (
      <Stack direction="horizontal" className="Body">
        <Container fluid className="Content constrained" style={{paddingBottom:"120px"}}>
          { children }
        </Container>
      </Stack>
  );
}