// Main App

// Infra
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container';

// Components
import Header from './components/Header';

// Pages
 import HomePage from './pages/HomePage';

// Functions 

export default function App() {
  return (
    <Container fluid className="App">
      <BrowserRouter>
        <Header />

        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}
